<template>
<div style="width:100%">
  <v-card>
    <v-card-text>
      <v-form ref="form">
        <v-row>
          <v-col cols="12" md="6">
            <v-autocomplete v-model="buscar_id" :rules="[rules.requerido]" :items="items.tipobusquedas" :loading="loadingData" item-text="descripcion" item-value="id" :color="colores.primario" label="TIPO DE BÚSQUEDA"></v-autocomplete>
          </v-col>
          <v-col cols="12" md="5">
            <v-text-field v-model="dato" :color="colores.secundario" maxlength="40" counter="40" label='* INFORMACIÓN A BUSCAR' autocomplete="off"></v-text-field>
          </v-col>
          <v-col cols="12" md="1" align-self="center">
            <v-btn @click="validar" :loading="loadingBuscar" block><v-icon>mdi-magnify</v-icon></v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-text>
      <listados :session="session" :colores="colores" :items="items" :loadingBuscar="loadingBuscar" :item_registros="item_registros"></listados>
    </v-card-text>
  </v-card>

</div>
</template>

<script>
// import listados from '@/components/crm/listadosComponent.vue'
import recBusquedas from '@/js/rec_busquedas.js'

export default {
  name: 'crm_buscarComponent',
  components: {
    listados: () => import('@/components/crm/listadosComponent.vue')
  },
  mixins: [recBusquedas],
  props: ['colores', 'rules', 'items', 'loadingData', 'session'],
  data: () => ({
    buscar_id: 0,
    dato: '',
    loadingBuscar: false,
    item_registros: []
  }),
  methods: {
    validar () {
      if (this.$refs.form.validate()) {
        this.rec_busquedas_tipos(this.buscar_id, this.dato)
      }
    }

  }
}
</script>
